import { Badge, Icon, Stack, Typography } from "components";
import classnames from "classnames";
import PropTypes from "prop-types";

function LinkItem({ className, description, icon, label, loading, ...props }) {
  return (
    <Stack
      alignItems="center"
      className={classnames("BupLink-item", className)}
      flexDirection="row"
      justifyContent="space-between"
      {...props}
    >
      <Stack spacing={0.75}>
        <Typography variant="p">
          {!loading && label}{" "}
          {props.badge > 0 && (
            <Badge badgeContent={props.badge} color="error" />
          )}
        </Typography>
        <Typography variant="span">{!loading && description}</Typography>
      </Stack>
      <Icon name={icon} oval />
    </Stack>
  );
}

LinkItem.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
};

export default LinkItem;
