import { useEffect, useState } from "react";
import { useAuthStore } from "store";
import helper from "utils/helper";

function useUploadImage(url) {
  const [loading, setLoading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [file, setFile] = useState(null);

  const { accessToken } = useAuthStore();

  const uploadImage = (file) => {
    const apiKey = process.env.MONOLITH_SERVICE_API_KEY;

    const formData = new FormData();
    formData.append("files", file);

    fetch(url, {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Api-Key": `Apikey ${apiKey}`,
      },
    })
      .then((res) => res.json())
      .then((res) => setPhotoUrl(res.data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (helper.validateImage(file)) {
      setLoading(true);
      uploadImage(file);
    }
  }, [file]);

  return {
    loading,
    photoUrl,
    setFile,
  };
}

export default useUploadImage;
