import classnames from "classnames";
import { Icon } from "components";
import PropTypes from "prop-types";
import { useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import PremiumAlert from "./Premium";

function BupAlert({ children, severity, variant, ...props }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    !props.hidden && (
      <Collapse in={open} {...(props.disableTransition && { timeout: 0 })}>
        {severity === "premium" ? (
          <PremiumAlert {...props} handleClose={handleClose}>
            {children}
          </PremiumAlert>
        ) : (
          <Alert
            action={
              !props.disableCloseButton && (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleClose}
                  title="Cerrar"
                >
                  <Icon name="close" />
                </IconButton>
              )
            }
            className={classnames("BupAlert-root", props.className)}
            {...{ severity, variant }}
            sx={{ mb: 2 }}
          >
            {props.title && <AlertTitle>{props.title}</AlertTitle>}
            {children}
          </Alert>
        )}
      </Collapse>
    )
  );
}

BupAlert.propTypes = {
  ...Alert.propTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  hidden: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  severity: PropTypes.oneOf(["error", "warning", "info", "success", "premium"]),
  title: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  disableCloseButton: PropTypes.bool,
  disableTransition: PropTypes.bool,
};

export default BupAlert;
