import Card from "@mui/material/Card";
import { Fragment, Link } from "components";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupCard({ children, variant = "preview", ...props }) {
  if (props.hidden) return null;

  const Wrapper = props.to ? Link : Fragment;

  return (
    <Wrapper disabled={props.disabled} to={props.to} params={props.params}>
      <Card
        className={classnames("BupCard-root", props.className, {
          "BupCard-preview": variant === "preview",
          "BupCard-listed": variant === "listed",
          "BupCard-mosaic": variant === "mosaic",
        })}
      >
        {children}
      </Card>
    </Wrapper>
  );
}

BupCard.propTypes = {
  ...Card.propTypes,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  params: PropTypes.any,
  to: PropTypes.any,
  variant: PropTypes.oneOf(["preview", "listed", "mosaic", "thumbnail"]),
};

export default BupCard;
