import { useAuth } from "contexts/auth/authProvider";
import { useConfig, useConsole, useNetwork } from "session/networkProvider";
import { useEffect } from "react";
import { paths, useRouter } from "routes";

/**
 * A higher-order component (HOC) that adds access control to a component.
 * If the user is not an admin, it redirects them to the overview page.
 *
 * @param {React.Component} Component - The component to wrap with access control.
 * @returns {React.Component} A wrapped component with access control.
 */
function withAccess(Component) {
  return function WithAccess(props) {
    // Get the 'navigate' function from the useHistory hook
    const router = useRouter();
    const { user } = useAuth();

    useEffect(() => {
      // Check if the user is not an admin
      if (!user.hasFullAccess) {
        // Redirect the user to the overview page
        router.push(paths.overview);
      }
    }, [router, user.hasFullAccess]); // The empty dependency array ensures this effect runs once, similar to componentDidMount

    // Render the wrapped component with access control
    return <Component {...props} />;
  };
}

export { useAuth, useConfig, useConsole, useNetwork, withAccess };
