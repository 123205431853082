import { Box, Button, Icon, Paper, Stack } from "components";
import Link from "components/navigation/Link";
import { useRouter } from "routes";
import Typography from "./Typography";
import classnames from "classnames";
import PropTypes from "prop-types";

function Item({ children, chip, className, id, variant, ...props }) {
  const { push } = useRouter();

  const handleOnClick = () => {
    if (typeof props.onClick === "function") props.onClick();
    else if (props.to) push(props.to);
  };

  return (
    <Box className="BupItem-root" hidden={props.hidden} id={id}>
      <Paper>
        <Stack className="BupItem-wrapper" spacing={2.5}>
          <Stack
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            className="BupItem-header"
            useFlexGap
          >
            {props.title && (
              <Typography variant={variant || "h3"}>
                {props.title}
                {chip}
              </Typography>
            )}
            {props.icon && <Icon className="BupIcon-item" name={props.icon} />}
            {props.link && (
              <Link className="BupItem-link" to={props.to}>
                {props.link}
              </Link>
            )}
          </Stack>
          {props.span && <Typography variant="p">{props.span}</Typography>}
          <Box
            className={classnames("BupItem-content", className)}
            sx={props.sx}
          >
            {children}
          </Box>
        </Stack>
        {props.button && (
          <Button
            className="BupItem-action"
            color="inherit"
            disabled={props.disabled}
            onClick={handleOnClick}
            loading={props.loading}
            variant="outlined"
            size="large"
          >
            {props.button}
          </Button>
        )}
      </Paper>
    </Box>
  );
}

Item.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.object,
  title: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  variant: PropTypes.string,
};

export default Item;
