import Box from "@mui/material/Box";
import classnames from "classnames";
import PropTypes from "prop-types";

import Input from "./Input";
import Select from "./Select";

function Field({ className, datalist, form, name, textarea, ...args }) {
  if (args.hidden) return;

  const required = form.required?.includes(name) || args.required;
  const error = form.errors[name] || args.error;
  const type = args.type || "text";
  const value = form.values[name];

  const props = {
    id: name,
    name,
    className: classnames({
      "BupForm-error": error,
      capitalize: args.capitalize,
      danger: args.danger,
      lowercase: args.lowercase,
      prefix: args.prefix,
      sufix: args.sufix,
      uppercase: args.uppercase,
    }),
    disabled: args.disabled,
    multiple: args.multiple,
    onBlur: (e) => {
      if (typeof args.onBlur === "function") args.onBlur(e);
      form?.handleBlur(e);
    },
    optional: args.optional,
    onChange: (e) => {
      if (typeof args.onChange === "function") args.onChange(e);
      form.handleChange(e);
    },
    isValid: args.showCheckIcon,
    value: value !== undefined ? value : "",
    ref: args.input,
    required,
  };

  return (
    <Box
      className={classnames("BupForm-field", className, {
        [`w-${args.width}`]: args.width,
      })}
    >
      {args.label && (
        <Box className="BupForm-label" component="label" htmlFor={name}>
          {args.label} {required && "*"}
        </Box>
      )}
      {args.noLabel && (
        <Box className="BupForm-label BupLabel-empty" component="label">
          &nbsp;
        </Box>
      )}
      <Box className="BupForm-inline">
        {args.prefix && <span className="BupForm-prefix">{args.prefix}</span>}
        {args.options ? (
          <Select {...props} helper={args.helper} options={args.options} />
        ) : (
          <Input
            {...props}
            {...{ datalist, type }}
            placeholder={args.placeholder || args.label}
            textarea={textarea !== undefined}
          />
        )}
        {args.sufix && <span className="BupForm-sufix">{args.sufix}</span>}
      </Box>
      {error && <small>{args.message}</small>}
    </Box>
  );
}

Field.propTypes = {
  className: PropTypes.string,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  textarea: PropTypes.bool,
  type: PropTypes.string,
};

export default Field;
