import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Fragment } from "components";
import classnames from "classnames";
import PropTypes from "prop-types";
import { GRID } from "consts";

function BupGrid({ children, className, disabled, hidden, ...props }) {
  if (hidden) return null;

  const Wrap = props.container ? Box : Fragment;
  const { columnSpacing, rowSpacing, spacing, ...others } = props;

  return (
    <Wrap sx={GRID.grow}>
      <Grid
        className={classnames("BupGrid-root", className, {
          "Bup-disabled": disabled,
        })}
        {...(props.container
          ? {
              columnSpacing: columnSpacing || spacing || GRID.spacing,
              rowSpacing: rowSpacing || spacing || GRID.spacing,
            }
          : {})}
        {...others}
      >
        {children}
      </Grid>
    </Wrap>
  );
}

BupGrid.propTypes = {
  ...Grid.propTypes,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
};

export default BupGrid;
