import Button from "@mui/material/Button";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupButton({
  active,
  children,
  className,
  loading,
  selected,
  square,
  size = "medium",
  variant = "contained",
  ...props
}) {
  if (props.hidden) return null;

  return (
    <Button
      className={classnames("BupButton-root", className, {
        "BupButton-square": square,
        "Bup-active": active,
        "Bup-disabled": props.disabled,
        "Bup-loading": loading,
        "Bup-selected": selected,
      })}
      {...{ size, variant }}
      disableElevation
      {...props}
    >
      {children}
    </Button>
  );
}

BupButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  selected: PropTypes.bool,
  ...Button.propTypes,
};

export default BupButton;
