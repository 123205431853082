import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useRouterTab } from "hooks/common";
import PropTypes from "prop-types";
import { GRID } from "consts";

function TabPanel({ children, spacing, value, ...props }) {
  const [active] = useRouterTab();

  return (
    <Box
      aria-labelledby={`tab-${value}`}
      className="BupTabs-panel"
      component="article"
      hidden={active !== value}
      id={`tabpanel-${value}`}
      role="tabpanel"
      {...props}
    >
      {active === value && (
        <Stack spacing={spacing || GRID.spacing} useFlexGap>
          {children}
        </Stack>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  ...Box.propTypes,
  children: PropTypes.node,
  component: PropTypes.string,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  value: PropTypes.number.isRequired,
};

export default TabPanel;
