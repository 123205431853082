import { gql } from "@apollo/client";

const CREATE_GROUP = gql`
  mutation CreateGroup(
    $info: CreateGroupInfoInput!
    $interests: [String]!
    $published: Boolean!
    $slots: Int
  ) {
    createGroup(
      info: $info
      interests: $interests
      published: $published
      slots: $slots
    ) {
      _id
    }
  }
`;

export default CREATE_GROUP;
