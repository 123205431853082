import { gql } from "@apollo/client";
import { COMMENT_FIELDS } from "fragments";

const CREATE_COMMENT = gql`
  mutation AddComment($id: ID!, $from: ID!, $message: String!) {
    addComment(_id: $id, from: $from, message: $message) {
      comments {
        ...CommentFields
      }
    }
  }
  ${COMMENT_FIELDS}
`;

export default CREATE_COMMENT;
