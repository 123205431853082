import { usePathname, useSearchParams, useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";
import { useConsole } from "contexts";
import { modules, pages } from "./";

const BASE_URL = process.env.BASE_URL;

export default function useNavigation() {
  const { cpanel, screen } = useConsole();
  const pathname = usePathname();
  const params = useSearchParams();
  const router = useRouter();

  const tab = params?.get("tab");

  const current = useMemo(
    () => pathname?.slice(0, -1).replace(BASE_URL, ""),
    [pathname]
  );

  const matchPattern = useCallback((path, pattern) => {
    if (typeof pattern !== "string" || !path) return false;

    const pathSegments = path.split("/").filter(Boolean);
    const patternSegments = pattern.split("/").filter(Boolean);

    if (pathSegments.length !== patternSegments.length) {
      return false;
    }

    for (let i = 0; i < patternSegments.length; i++) {
      if (patternSegments[i].startsWith(":")) {
        continue;
      }

      if (pathSegments[i] !== patternSegments[i]) {
        return false;
      }
    }

    return true;
  }, []);

  const findModule = useCallback(() => {
    return pages[
      modules.findIndex((module) =>
        module.routes.some((route) => matchPattern(current, route))
      )
    ];
  }, [current, matchPattern]);

  const generatePath = useCallback((href, params, searchParams) => {
    const isString = typeof href === "string";
    let path = isString ? href : href[0];

    if (path.includes("/:")) {
      const [key, slug] = path.split("/:");
      path = key + "/" + params?.[slug];
    }

    if (searchParams?.tab) {
      const search = new URLSearchParams(searchParams);
      path += "?" + search?.toString();
    }

    return BASE_URL + path;
  }, []);

  const isActive = useCallback(
    (routes) => routes.some((route) => matchPattern(current, route)),
    [current, matchPattern]
  );

  const push = useCallback(
    (href, params, tab = 0) => {
      const path = generatePath(href, params, { tab });
      const expanded = !screen.mobile ? cpanel.values.expanded : true;
      if (tab === 0) cpanel.setValues({ expanded, tab });
      router.push(path);
      if (tab !== 0) cpanel.setValues({ expanded, tab });
    },
    [cpanel, router, screen, generatePath]
  );

  const setTab = useCallback(
    (tab) => {
      const path = generatePath(current, {}, { tab });
      cpanel.setValues({ tab });
      router.push(path);
    },
    [cpanel, router, current, generatePath]
  );

  return Object.assign({}, router, {
    current,
    isActive,
    findModule,
    generatePath,
    matchPattern,
    push,
    tab,
    setTab,
  });
}
