import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { Button } from "components";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BupDialog({
  alert,
  confirmText,
  open,
  handleClose,
  handleConfirm,
  title,
  variant,
}) {
  const handleAccept = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <Dialog
      aria-describedby="alert-dialog"
      className="BupDialog-root"
      keepMounted
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle>{title || "Confirmación"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog">{alert}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          Cancelar
        </Button>
        <Button color={variant || "primary"} onClick={handleAccept}>
          {confirmText || "Aceptar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BupDialog.propTypes = {
  alert: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "danger"]),
};

export default BupDialog;
