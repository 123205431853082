import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import classnames from "classnames";
import Image from "next/image";
import PropTypes from "prop-types";
import helper from "utils/helper";

function BupImage({ children, className, src, onClick, ...props }) {
  if (props.hidden) return;

  if (!src || src == "null") {
    return (
      <Skeleton
        component="img"
        className={classnames("BupImage-root BupSkeleton-image", className)}
        variant="rectangular"
      />
    );
  }

  const regex = /^https?:\/\//;
  const includesHttp = regex.test(src);
  const url = includesHttp ? src : helper.getImageUrl(src);

  if (children) {
    return (
      <Box
        className={classnames("BupImage-root BupImage-bg", className)}
        style={{ backgroundImage: `url(${url})` }}
        {...{ ...props, onClick }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Image
      className={classnames("BupImage-root", className)}
      onClick={onClick}
      //layout="responsive"
      alt="BondUP"
      src={url}
      {...props}
    />
  );
}

BupImage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  src: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default BupImage;
