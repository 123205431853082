import pages from "./routes";
import obj from "utils/object";

/**
 * Generate a mapping of paths to page names by iterating through the 'pages' array.
 * Each page contains a 'routes' object, and this function extracts relevant information to build the path mapping.
 *
 * @returns {object} A mapping of paths to page names.
 */
export const paths = pages.reduce(
  (paths, view) =>
    obj.mergeObject(
      paths,
      ...Object.entries(view.routes).map(([key, val]) => ({
        [key]: [...val.slice(2), view.name], // Build the path mapping by combining route segments and the page name
      }))
    ),
  {}
);

/**
 * Generate a mapping of modules by iterating through the 'pages' array.
 */
export const modules = pages.map(({ routes, ...args }) => ({
  ...args,
  routes: Object.entries(routes).map((route) => route[1][2]),
}));
