import { useLazyQuery } from "gql";
import { useFormik } from "hooks";
import { GET_POSTS } from "queries/community";
import { useEffect } from "react";
import obj from "utils/object";

function useDiscussion({ refId, target, setTotal }) {
  const [getPosts, { called, data, loading }] = useLazyQuery(GET_POSTS);

  const discussion = useFormik({
    initialValues,
  });

  const handleLoad = () =>
    getPosts({
      limit: discussion.values.limit,
      offset: discussion.values.offset,
      target,
      to: refId,
    });

  useEffect(() => {
    if (called && !loading && data.instances) {
      const { instances, total } = data;
      const ids = obj.getIds(discussion.values.posts);
      const posts = [];
      let errors = 0;

      instances.forEach((x) => {
        if (!ids.includes(x._id)) posts.push(x);
        else errors++;
      });

      if (typeof setTotal === "function") {
        setTotal(total);
      }

      if (posts.length) {
        discussion.setValues({
          posts: [...discussion.values.posts, ...posts],
          offset: discussion.values.offset + 1,
          errors: discussion.values.errors + errors,
          total,
        });
      } else {
        discussion.setValues({
          errors: discussion.values.errors + errors,
          total,
        });
      }
    }
  }, [data, loading, called]);

  useEffect(() => {
    discussion.resetForm();
    handleLoad();
  }, [refId]);

  return {
    discussion,
    handleLoad,
    loading,
  };
}

const initialValues = {
  limit: 5,
  errors: 0,
  offset: 0,
  posts: [],
  total: 0,
};

export default useDiscussion;
