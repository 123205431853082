import { gql } from "@apollo/client";

const JOIN_GROUP = gql`
  mutation JoinGroup($id: ID!, $groupId: ID!) {
    joinGroup(_id: $id, groupId: $groupId) {
      groups {
        joined {
          _id
        }
      }
    }
  }
`;

export default JOIN_GROUP;
