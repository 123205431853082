import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { IconButton } from "components";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import classnames from "classnames";
import { useTrigger } from "hooks";
import PropTypes from "prop-types";
import { useEffect } from "react";
import helper from "utils/helper";
import str from "utils/string";

function BupModal({
  children,
  fullWidth,
  open,
  onClose,
  size,
  title,
  ...props
}) {
  useTrigger("keydown", (e) => {
    if (open && e.key === "Escape") {
      helper.updateClassList("Bup-modal", false);
      onClose();
    }
  });

  useEffect(() => {
    if (open) helper.updateClassList("Bup-modal", true);
    else helper.updateClassList("Bup-modal", false);
  }, [open]);

  return (
    <Modal
      className="BupModal-root"
      closeAfterTransition
      {...{
        ...props,
        onClose,
        open,
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Paper
        className={classnames("BupModal-paper", {
          [`BupModal-size${str.capitalize(size)}`]: size,
          "BupModal-fullWidth": fullWidth,
        })}
      >
        {title && (
          <Box className="BupModal-header">
            <Typography component="h2" variant="h6">
              {title}
            </Typography>
            <IconButton icon="close" onClick={onClose} />
          </Box>
        )}
        {children}
      </Paper>
    </Modal>
  );
}

BupModal.propTypes = {
  ...Modal.propTypes,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default BupModal;
