import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

function BupTypography({ children, height, width, ...props }) {
  if (props.hidden) return null;

  return (
    <Typography {...props}>
      {children || <Skeleton {...{ height, width }} variant="rounded" />}
    </Typography>
  );
}

BupTypography.propTypes = {
  ...Typography.propTypes,
  children: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BupTypography;
