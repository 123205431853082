import { gql } from "@apollo/client";

const DELETE_POST = gql`
  mutation DeletePost($id: ID!) {
    deletePost(_id: $id) {
      _id
    }
  }
`;

export default DELETE_POST;
