import { gql } from "@apollo/client";

const FRIEND_DELETE = gql`
  mutation DeleteFriend($from: ID!, $to: ID!) {
    deleteFriend(from: $from, to: $to) {
      _id
    }
  }
`;

export default FRIEND_DELETE;
